
import { Vue, Options } from 'vue-class-component';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import BaseSelect from '@/lib/components/Select/BaseSelect.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { Status as StatusModel } from '@/models/worklist.model';
import { IOption } from '@/lib';

@Options({
  components: { BaseIcon, BaseSelect, BaseTextInput, BaseButton },
  props: {
    status: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    statusIndex: {
      type: Number,
      default: 0
    },
    statusTitleLength: {
      type: Number,
      default: 0
    }
  }
})
export default class Status extends Vue {
  status!: StatusModel;

  get items() {
    return [
      {
        label: this.$t('platform.common.status')
      },
      {
        label: this.$t('custom.uhb.worklist-configuration.fhir-mapping')
      }
    ];
  }

  get fhirMappingOptions(): Array<IOption> {
    // TODO: Fix this hardcode once we confirm the fhir mapping api.
    return [
      {
        label: 'Planned',
        value: '899de6de-3f5e-452f-ae61-0828b9c01c04'
      },
      {
        label: 'In Progress',
        value: '9c5aa88c-0a09-4814-a602-5b93cd02d4b0'
      }
    ];
  }
}
