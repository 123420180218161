import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "grid gap-x-2 gap-y-3 my-2 mr-2",
    style: _normalizeStyle(`grid-template-columns: repeat(${_ctx.statusTitleLength + 1}, minmax(0, 1fr)) 50px;`)
  }, [
    _createVNode(_component_BaseTextInput, {
      modelValue: _ctx.status.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status.name) = $event)),
      class: "col-span-2 ml-4",
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "readonly"]),
    _createVNode(_component_BaseSelect, {
      modelValue: _ctx.status.fhirMapping.id,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.status.fhirMapping.id) = $event)),
      options: _ctx.fhirMappingOptions,
      placeholder: _ctx.$t('custom.uhb.worklist-configuration.select-fhir-mapping')
    }, null, 8, ["modelValue", "options", "placeholder"]),
    _createVNode(_component_BaseButton, {
      class: "w-12 h-12 rounded",
      color: "ghost",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('remove-status', _ctx.statusIndex)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseIcon, {
          class: "text-base",
          name: "delete"
        })
      ]),
      _: 1
    })
  ], 4))
}